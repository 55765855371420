<script lang="ts" setup>
import PageContent from '@/Components/PageContent.vue'
import PageLayout from '@/Layouts/PageLayout.vue'
import { Head, Link, usePage } from "@inertiajs/vue3"
import { computed } from 'vue'

const page = usePage()
const props = defineProps<{ status: number, title?: string, description?: string, showLogout?: boolean, retryAfter?: number }>()

const title = computed(() => {
  return props.title ?? {
    503: '503 Service Unavailable',
    500: '500 Server Error',
    429: '429 Rate Limit Exceeded',
    419: '419 Session Expired',
    404: '404 Page Not Found',
    405: '405 Method Not Allowed',
    403: '403 Forbidden',
    401: '401 Unauthorized',
    400: '400 Bad Response',
  }[props.status]
})

const description = computed(() => {
  return props.description ?? {
    503: 'Sorry, we are doing some maintenance. Please check back soon.',
    500: 'This is an error on our end. Please refresh and try again, or reach out to our support team at <a href="mailto:support@luminsports.com" target="_blank" class="underline">support@luminsports.com</a>.',
    429: `You have sent too many requests in a given amount of time. Please try again ${ props.retryAfter ? `in ${props.retryAfter} seconds` : 'later'}.`,
    419: 'Sorry, your session has expired. Please refresh and try again.',
    404: 'Sorry, the page you are looking for could not be found.',
    403: 'You do not have permission to access this page.',
    401: 'You must be logged in to access this page.',
    400: 'Please contact support if the issue persists.',
  }[props.status]
})

const showLogoutButton = computed(() => {
  return props.showLogout ?? ! ! page.props.auth.user
})

const showBackToLoginButton = computed(() => {
  return ! showLogoutButton.value
})
</script>

<template>
  <PageLayout :title="title">
    <Head :title="title" />

    <PageContent>
      <p class="mb-10 text-gray-300 font-medium subpixel-antialiased" v-html="description" />

      <Link
        v-if="showLogoutButton"
        href="/logout"
        method="post"
        as="button"
        class="mx-auto text-sm text-primary font-caps font-medium uppercase hover:text-primary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 focus:ring-offset-gray-800"
      >Log Out
      </Link>

      <Link
        v-if="showBackToLoginButton"
        href="/"
        class="mx-auto text-sm text-primary font-caps font-medium uppercase hover:text-primary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 focus:ring-offset-gray-800"
      >Back to login
      </Link>
    </PageContent>
  </PageLayout>

</template>
